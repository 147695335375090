<script>
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
// import FlatPickr from 'vue-flatpickr-component';
import VueApexCharts from 'vue-apexcharts';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
// import { BButton } from 'bootstrap-vue';
// import { formatNumber1 } from '@/util/number';

export default {
    name: 'AppRegistrationStatistics',
    components: {
        ProgressLinear,
        // FlatPickr,
        apexchart: VueApexCharts
        // BButton
    },
    data() {
        return {
            requestBody: {
                date_start: null,
                date_end: null,
                merchant_id: parseInt(MERCHANT_ID)
            },
            isLoaded: false,
            series: [],
            chartOptions: {
                colors: [
                    '#006f62',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#002296',
                    '#b03966',
                    '#057f5f'
                ],
                chart: {
                    id: 'registration',
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 700
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true
                                }
                            }
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 4,
                        hideZeroBarsWhenGrouped: false,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    color: '#28c76f',
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    }
                },
                // dataLabels: {
                //     formatter: function (val) {
                //         return formatNumber1(val);
                //     }
                // },
                xaxis: {
                    categories: []
                },
                legend: {
                    position: 'bottom',
                    offsetY: 6
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    intersect: false,
                    shared: true,
                    y: {
                        show: true,
                        formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    }
                }
            }
        };
    },
    async mounted() {
        await this.fixMonthlyStatisticsDates();
    },
    methods: {
        async fixMonthlyStatisticsDates() {
            const date = new Date();
            const year = date.getUTCFullYear(); // Get the full year (4 digits)
            const beforeMonth = String(date.getMonth()).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const currentMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary
            this.requestBody.date_start = `${year}-${beforeMonth}-${day}`;
            this.requestBody.date_end = `${year}-${currentMonth}-${day}`;
            await this.getStatistics();
        },

        async getStatistics() {
            this.isLoaded = false;
            this.chartOptions.xaxis.categories.length = 0;
            try {
                const { data } = await api.coin.fetchRegistrationStatistics(this.requestBody);
                const response = data.data;
                this.chartOptions.xaxis.categories = response.map((item) => {
                    // return item['recorded_at'];
                    return item['recorded_at'].substring(5);
                });

                // Collect all keys
                const keys = new Set();
                response.forEach((obj) => {
                    Object.keys(obj).forEach((key) => {
                        if (key !== 'recorded_at') {
                            keys.add(key);
                        }
                    });
                });

                // Convert to readyArray
                this.series = Array.from(keys).map((key) => {
                    const data = response.map((obj) => obj[key] || 0);
                    return { name: key, data };
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoaded = true;
            }
        }
    }
};
</script>

<template>
    <div class="statistics">
        <p class="p-1 font-medium-5 font-weight-bold">{{ $t('titles.registration_statistics') }}</p>
        <div class="row align-items-end">
            <!--            <div class="col-12 col-md-10 d-flex flex-wrap align-items-center">-->
            <!--                <div class="col-12 col-sm-6">-->
            <!--                    <label for="start_date">{{ $t('titles.date_start_at') }}</label>-->
            <!--                    <flat-pickr-->
            <!--                        v-model="requestBody.date_start"-->
            <!--                        :config="{-->
            <!--                            allowInput: true,-->
            <!--                            time_24hr: true-->
            <!--                        }"-->
            <!--                        :placeholder="$t('choose.date')"-->
            <!--                        class="form-control"-->
            <!--                        name="date"-->
            <!--                        id="start_date"-->
            <!--                    />-->
            <!--                </div>-->
            <!--                <div class="col-12 col-sm-6 mt-1 mt-sm-0">-->
            <!--                    <label for="end_date">{{ $t('titles.date_end_at') }}</label>-->
            <!--                    <flat-pickr-->
            <!--                        v-model="requestBody.date_end"-->
            <!--                        :config="{-->
            <!--                            allowInput: true,-->
            <!--                            time_24hr: true-->
            <!--                        }"-->
            <!--                        :placeholder="$t('choose.date')"-->
            <!--                        class="form-control"-->
            <!--                        name="date"-->
            <!--                        id="end_date"-->
            <!--                    />-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <div class="col-12 col-md-2 px-2">-->
            <!--                <b-button @click="getStatistics" class="w-100 mt-1 mt-md-0" variant="primary">-->
            <!--                    <feather-icon icon="SearchIcon" />-->
            <!--                </b-button>-->
            <!--            </div>-->
            <progress-linear v-if="!isLoaded" />
            <div v-else id="chart" class="col-12">
                <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptions"
                    :series="series"
                ></apexchart>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
